.home-section {
  padding: 3em 0;
}

.home-hero-section {
  text-align: center;
  padding: 20vh 0;
  background-image: linear-gradient(
      rgba(245, 229, 199, 0.884),
      rgba(245, 229, 199, 0.884)
    ),
    url('../../../assets/img/jornada.jpg');
  background-size: cover;
  background-position: center;
}

.home-gray {
  background-color: #f2f2f2;
}

img {
  width: 100%;
  margin-top: 2em;
}
